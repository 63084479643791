@import "src/variables"

.table
    width: 100%
    border-spacing: 0px
    text-align: left
    white-space: nowrap
    overflow: hidden
    margin-top: 0px
    tbody > tr:hover
        transition: background 0.15s
        background:  $color-lightgrey4
    th
        border-bottom: solid 1px $color-lightgrey2
        font-weight: 700
        font-size: 14px
        color: $color-grey
    td
        font-weight: 400
        font-size: 14px
        border-top: solid 1px $color-lightgrey2
        color: $color-darkgrey1

    th, td
        height: 30px
        vertical-align: middle

    th:nth-child(1)
        padding-left: 10px

    td:nth-child(1)
        width: 10px
        padding-left: 10px

    td:nth-child(2)
        width: 10px

    td:nth-child(3)
        padding-left: 6px
        padding-right: 12px

    td:nth-last-child(1)
        width: 1em

.action-icon
    padding: 4px
    cursor: pointer
    margin-right: 4px
    color: $color-grey1

.align-right
    text-align: right

    div.bp5-popover-target
        display: inline-block

.highlighted-row
    background-color: $color-yellow

.table-info
    margin: 6px auto 6px 2em
    border-spacing: 0px
    text-align: left
    white-space: nowrap
    overflow: hidden

    th:nth-child(3)
        padding: 1px 12px 1px 6px

    td
        font-weight: 400
        font-size: 14px
        border-top: none

.border-left
    border-left: 3px solid $color-lightgrey2
    padding: 1em

.action-title
    font-weight: 700
    font-size: 14px

.no-border
    border-top: none !important

.underlined-dotted
    text-decoration: underline dotted

.filter-icon
    cursor: pointer
    padding-right: 6px

.no-hover
    pointer-events: none

.bp5-menu-item.bp5-active
    background-color: #faca11
    color: #000

.bp5-menu-item.bp5-active::before, .bp5-menu-item.bp5-active .bp5-menu-item-icon, .bp5-menu-item.bp5-active .bp5-menu-item-selected-icon, .bp5-menu-item.bp5-active .bp5-submenu-icon
    color: #000


