.admin-page
  text-align: left
  padding: 32px
  display: flex
  flex-direction: column
  gap: 16px

  h1
    margin: 0

  .spacer
    display: flex
    flex: 1 1 auto

  .flex-container
    display: flex
    flex-direction: row
    flex-wrap: wrap
    gap: 16px

  .section
    margin: 16px 0 8px 0

  .row
    display: flex
    align-items: center
    flex-direction: row
    gap: 8px

    > span:first-child
      flex-basis: 200px

  .bp5-popover-target
    display: flex

#dunning-container
  .admin-page
    .card
      display: flex
      flex-direction: column
      gap: 14px
      padding: 20px
      margin: 0
      flex-grow: 1
