@import "src/variables"

.list-header-grid
    display: grid
    grid-template-columns: auto 1fr auto auto auto auto
    grid-gap: 1em
    place-items: center
    padding: 10px
    font-weight: 400
    font-size: 14px

.list-header-grid > div
    padding: 5px

.header-menu-grid
    display: grid
    grid-template-columns: 1fr 1fr
    grid-gap: 6em
    place-items: top
    background: linear-gradient(#D5D7D5,#D5D7D5) center/2px 100% no-repeat
    position: relative
    padding: 2em

.inner-grid
    display: grid
    grid-template-columns: auto min-content
    grid-gap: 2em
    padding: 0px 0px 8px 0px
    font-size: 14px

.bp5-popover
    transform: none !important
