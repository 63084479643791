@import "src/variables"

.right-text
  text-align: right

.rule-condition-row
  display: flex
  align-items: center
  margin-bottom: 8px
  padding: 0.25em

.right-left-padding
  padding-right: 0.25em
  padding-left: 0.25em

.rule-condition-inputs
  margin-left: 4px
  flex: 1 1 auto
  display: flex
  align-items: center
  gap: 4px

input[type=number]
  -moz-appearance: textfield

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button
  -webkit-appearance: none

.bp5-intent-danger .bp5-multi-select
  box-shadow: 0 0 0 0 rgba(205, 66, 70, 0), 0 0 0 0 rgba(205, 66, 70, 0), inset 0 0 0 1px #cd4246, inset 0 0 0 1px rgba(17, 20, 24, 0.2), inset 0 1px 1px rgba(17, 20, 24, 0.3)