@import "src/variables"

.dunning-checkbox-container
    display: inline-flex
    justify-content: flex-start
    align-items: center
    position: relative
    -webkit-touch-callout: none
    -webkit-user-select: none
    -khtml-user-select: none
    -moz-user-select: none
    -ms-user-select: none
    user-select: none

.dunning-checkbox-checked
    min-height: 16px
    min-width: 16px
    background: #FDCA10
    border: 1px solid $color-yellow1
    border-radius: 3px
    margin-right: 6px

.dunning-checkbox-unchecked
    min-height: 16px
    min-width: 16px
    background: #FFFFFF
    border: 1px solid $color-lightgrey1
    border-radius: 3px
    margin-right: 6px

.dunning-check-tick
    position: absolute

.dunning-checkbox-disabled
    opacity: 40%
    cursor: no-drop

.bp5-icon-small-tick
    position: absolute
