@import "src/variables"
@import "~normalize.css"
@import "~@blueprintjs/datetime2/lib/css/blueprint-datetime2.css"
@import "~@blueprintjs/datetime/lib/css/blueprint-datetime.css"
@import "~@blueprintjs/select/lib/css/blueprint-select.css"
@import "~@blueprintjs/core/lib/css/blueprint.css"
@import "~@blueprintjs/icons/lib/css/blueprint-icons.css"
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap')

#vue_content
    padding: 0 !important

body //to prevent the dunning ui changing font of the crmi parent
   font-family: 'Roboto', Arial, Helvetica, sans-serif
   line-height: 20px
   font-size: 12px

.a, a:hover //to prevent the dunning ui changing font of the crmi parent
       color: var(--powercloud-black)
       text-decoration: unset

#uniform-dunning-file-upload
    display: none

.margin-right-auto
    margin-right: auto

.padding
    padding: 20px

.padding-bottom
    padding-bottom: 10px

.padding-top
    padding-top: 10px

.padding-right
    padding-right: 10px

.padding-left
    padding-left: 10px

.margin-bottom
    margin-bottom: 10px

.bp5-menu
    font-size: 14px

.bp5-input::placeholder
    color: $color-input

.bp5-button.bp5-minimal:hover
    background: $color-buttonHover

.bp5-button .bp5-minimal:active
    background: $color-buttonActive

.bp5-tag-remove
    min-height: inherit

.bp5-datepicker-caption select + .bp5-icon
    right: 3px !important

.bp5-icon-double-caret-vertical
    right: 10px !important

#save-button, #switch-button
    background: $color-yellow4
    border: 1px solid $color-yellow1
    box-shadow: none
    color: black

#save-button:hover, #switch-button:hover
    background: $color-yellow3
    border: 1px solid $color-yellow1
    box-shadow: none
    color: black

#cancel-button
    background: white
    border: 1px solid $color-yellow3
    box-shadow: none
    color: black

#cancel-button:hover
    background: $color-yellow5
    border: 1px solid $color-yellow1
    box-shadow: none
    color: black

.dunning-bold-title
    font-size: 14px
    font-weight: 600
    padding-bottom: 10px

#dunning-container
    .App
        text-align: center
        vertical-align: revert
        font-weight: 400 !important
        font-size: 14px !important

    .title
        text-align: left
        font-weight: 700
        font-size: 19.6px
        line-height: 30px
        margin: 1em

    .card
        padding: 0
        margin: 1em
        .flex
            display: flex
            padding: 1em
            &.gap
                gap: 0.5em
            .fill
                display: flex
                flex: 1 1 auto
            &.center
                align-items: center

    .spinner
        padding: 4em

    .left
        margin: auto
