@import "src/variables"

.pc-button
    fill: black
    color: black
    font-weight: normal


.pc-button.bp5-button .bp5-icon
    fill: black
    color: black

.pc-button .bp5-button-text
    white-space: nowrap

.bp5-button.pc-button:active, .bp5-button.pc-button:focus
    outline: none

.bp5-button.pc-button.primary
    fill: black
    background: $color-yellow4
    border: 1px solid $color-yellow1
    box-shadow: none
    color: black

.bp5-button.pc-button.primary:hover
    background: $color-yellow3
    color: black

.bp5-button.pc-button.primary:active
    background: $color-yellow2
    color: black

.bp5-button.bp5-disabled.pc-button.primary
    background: $color-yellow4
    border: 1px solid $color-yellow1
    color: black
    opacity: 0.5

.bp5-button.bp5-outlined.pc-button.secondary
    fill: black
    background: none
    border: 1px solid $color-yellow3
    box-shadow: none
    color: black

.bp5-button.bp5-outlined.pc-button.secondary:hover
    background: $color-yellow5
    color: black


.bp5-button.bp5-disabled.bp5-outlined.pc-button.secondary
    background: none
    border: 1px solid $color-yellow3
    opacity: 0.5
    color: $color-gray1

.bp5-button.bp5-outlined.pc-button.secondary:active
    background: $color-yellow4
    color: black

.bp5-button.pc-button.tertiary
    fill: black
    background: none
    box-shadow: none
    color: black

.bp5-button.pc-button.tertiary:hover
    background: #06060E1A
    box-shadow: none
    color: black

.bp5-button.pc-button.tertiary:active
    background: #06060E33
    color: black

.bp5-button.bp5-disabled.pc-button.tertiary
    background: none
    border: none
    opacity: 0.5

.bp5-button.bp5-outlined.pc-button.default
    background: none
    border: 1px solid $color-grey4
    color: black

.bp5-button.bp5-outlined.pc-button.default:hover
    background: rgba(6, 6, 14, 0.1)
    border: 1px solid $color-darkgrey5
    color: black

.bp5-button.bp5-outlined.pc-button.default:active
    background: rgba(6, 6, 14, 0.2)
    border: 1px solid $color-darkgrey5
    color: black

.bp5-button.bp5-outlined.bp5-disabled.pc-button.default
    background: none
    border: none
    color: black


