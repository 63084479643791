@import 'src/variables';

.lib-table-filter {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 8px;
  padding: 8px;
  &__input {
    flex: 1 1 50%;
  }
  &__label {
    margin-left: 3px;
    font-weight: 700;
  }
  &__labeled-box {
    min-width: 180px;
    display: flex;
    width: fit-content;
    flex-direction: column;
    align-items: flex-start;
  }
  &__button-text {
    display: flex;
    min-width: 130px;
  }
  &__rows {
    gap: 8px;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
  }
  &__row {
    gap: 8px;
    display: flex;
    flex-direction: row;
    flex: 1 1 auto;
    align-items: flex-end;
  }
  &__actions {
    gap: 8px;
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
    border-left: solid 1px $color-lightgrey1;
    padding-left: 15px;
  }
}
