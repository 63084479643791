@import "src/variables"

.dunning-radio-container
    display: inline-flex
    justify-content: flex-start
    align-items: center
    cursor: pointer

.dunning-radio-checked
    height: 14.25px
    width: 14.25px
    background: $color-black
    box-sizing: border-box
    border: 6px solid $color-yellow4
    border-radius: 50%
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3) inset

.dunning-radio-unchecked
    width: 10px
    height: 10px
    background: #FFFFFF
    border-radius: 50%


.dunning-radio-enabled
    color: $color-black
    font-weight: 400
    font-size: 15px

.dunning-radio-disabled
    opacity: 0.4
    cursor: not-allowed


.radio-button-checked, .radio-button-unchecked
    display: flex
    align-items: center
    justify-content: center
    box-sizing: border-box
    width: 16px
    height: 16px
    margin-right: 6px
    border-radius: 50%


.radio-button-checked
    border: 1px solid $color-yellow1

.radio-button-unchecked
    border: 1px solid $color-light-gray1

.radio-button-disabled
    opacity: 0.4
    cursor: not-allowed
