
@import "src/variables"

.bp5-tag-input-values
    .bp5-tag
        background-color: $color-gray2
        border-radius: 24px

        .bp5-tag-remove
            color: white
