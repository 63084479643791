@import 'src/variables';


.lib-table__wrapper {
  display:flex;
  overflow: auto;
  transform: rotateX(180deg);
}

.lib-table {
  flex: 1 1 auto;
  width: 100%;
  border-spacing: 0;
  transform: rotateX(180deg);

  &__table-header-row {
    height: 31px;
  }

  &__table-row {
    height: 31px;

    &:hover {
      background-color: $color-lightgrey4;
    }
  }

  &__table-cell {
    text-align: left;
    padding: 0 15px 1px;
    border-top: solid 1px $color-lightgrey2;
    vertical-align: middle;

    &:nth-child(1) {
      border-left: none;
    }

    &--header {
      border-top: none;
      border-bottom: solid 1px $color-lightgrey2;
      color: $color-gray1;
      font-weight: 700;
      font-size: 14px;
      line-height: 18px;
      border-left: none;
      white-space: nowrap;
    }

    &--fit-content {
      width: 1%;
    }

    &--fill {
      width: 100%;
    }

    &--small {
      padding: 0;
    }

    &--centered {
      text-align: center;
    }
  }

  &__subnav {
    padding: 10px 10px 10px 14px;
    display: flex;
    gap: 10px;
    align-items: center;

    &__tags {
      display: flex;
      gap: 8px;
      flex: 1 1 auto;
    }
  }

  &__loading-wrapper {
    padding: 15px;
  }

  &--light {
    .lib-table {
      &__table-cell {
        border: none;
      }

      &__table-row {
        &:hover {
          background-color: unset;
        }
      }

      &__table-row:nth-child(odd) {
        .lib-table__table-cell {
          background-color: $color-lightgrey4;
        }
      }
    }

    border: none;
  }
}
