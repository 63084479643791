@import "src/variables"
.settings
  padding: 4px
  text-align: left
  background: $gradient-gray1
  .settings-card
    margin: 1em
    align-items: flex-start
    flex-wrap: wrap
    text-align: center
    .pc-properties
      width: 30%
      .property-container
        display: flex
        justify-content: space-between
      .bp5-form-group
        display: flex
        width: 70%
        text-align: left

      .bp5-label
        text-align: left
        font-weight: bold

      .bp5-form-content
        text-align: left
  .flex-between
    display: flex
    width: 100%
    justify-content: space-between
