@import "src/variables"

.rule-condition-group
  border: solid $color-lightgrey1 1px
  font-weight: 600
  border-radius: 6px
  padding: 1.3em 1em 1em
  width: 100%
  margin-top: 2em
  position: relative

.rule-condition-title
  background-color: white
  position: absolute
  margin-top: -10px
  top: 0
  padding-left: 5px
  padding-right: 5px
