
$color-green: #0F9960
$color-blue: #137CBD
$color-grey: #8F948F
$color-darkgrey1: #0D0D0C
$color-darkgrey5: #494950
$color-navbarBlack: #12121b
$color-lightgrey: #8F948F
$color-lightgrey1: #BFBFC3
$color-lightgrey2: #D5D7D5
$color-lightgrey4: #E1E1E4
$color-grey1: #616561
$color-grey4: #8D8D91
$color-yellow: #FACA11
$color-background: #E5E5E5
$color-red: #c02835
$color-input: #7C7C81
$color-buttonHover: rgba(6, 6, 14, 0.1)
$color-buttonActive: rgba(6, 6, 14, 0.2)
$color-yellow1: rgba(199, 133, 34, 1)
$color-yellow2: #D99C1C
$color-yellow3: rgba(235, 179, 22, 1)
$color-yellow4: rgba(253, 202, 16, 1)
$color-yellow5: rgba(255, 246, 215, 1)
$color-gray1: #5A5A60
$color-gray2: #6B6B71
$color-gray3: #7C7C81
$color-light-gray1: #AFAFB2
$color-light-gray4: #E1E1E4
$color-light-gray5: #F2F2F4
$color-black: #030307
$gradient-gray1: linear-gradient(0deg, rgba(242,242,244,1) 0%, rgba(225,225,228,1) 100%)
$color-light-gray-bg-disabled-input: rgba(206, 217, 224, 0.5)
$color-light-gray-disabled-input: rgba(92, 112, 128, 0.6)

