@import "src/variables"

%icon-style
    margin-top: 4px
    width: 30px
    height: 20px
    border-radius: 11px
    align-items: center
    display: flex
    justify-content: center
    .pc-icon
        width: 16px
        height: 16px
        fill: white

.bp5-menu-item > .pc-icon
    margin-top: 2px
    height: 16px

.pc-icon
    fill: black
    display: flex

.archive-fill
    @extend %icon-style
    background: #616561

.check-line
    @extend %icon-style
    background: $color-green

.flag-line
    @extend %icon-style
    background: $color-green

.pencil-ruler-line
    @extend %icon-style
    background: $color-blue

.retry-blue
    @extend %icon-style
    fill: white
    background: $color-blue

.clock-blue
    @extend %icon-style
    color: white
    background: $color-blue

.cancelled
    @extend %icon-style
    fill: white
    background: $color-grey1

.warning-red
    @extend %icon-style
    color: white
    border-radius: 11px
    padding: 0px 6px 2px 6px
    background: $color-red

.download-2-fill
    font-size: 20px
    cursor: pointer

.bp-cog
    cursor: pointer
