@import "src/variables"

.dunning-selection
    padding: 4px
    text-align: left
    background: $gradient-gray1

    .bp5-disabled
        opacity: 80%

    .bp5-form-group.bp5-disabled .bp5-label
        color: $color-gray1 !important

    .bp5-input:disabled, .bp5-input.bp5-disabled
        background-color: $color-light-gray5
        border-radius: 4px
        border: 1px solid $color-light-gray1
        box-shadow: none
        color: $color-gray3
        cursor: not-allowed
        resize: none

    .radio-title
        font-weight: bold

    .bp5-disabled .bp5-form-content
        background-color: $color-light-gray5
        color: $color-gray3
        cursor: not-allowed

        .bp5-button.bp5-outlined:hover
            cursor: not-allowed

    .bp5-disabled
        .bp5-button-text
            opacity: 40%

    .date-input
        .bp5-input
            background: url(../../icons/svg/icon-calendar.svg) no-repeat scroll 7px 7px
            padding-left: 30px

        .bp5-disabled
            background-color: $color-light-gray5
            cursor: not-allowed
            resize: none
            color: $color-gray3

    .bp5-input.bp5-disabled
        background: url(../../icons/svg/icon-caret-down.svg) no-repeat scroll
        background-position: right 4.5% bottom 45%

    .bp5-input.bp5-active
        background: url(../../icons/svg/icon-caret-down.svg) no-repeat scroll
        background-position: right 4.5% bottom 45%

    .bp5-multi-select
        background: url(../../icons/svg/icon-caret-down.svg) no-repeat scroll
        background-position: right 4.5% bottom 45%

    .p-info-text
        font-weight: bold
    .padding
        padding: 15px
    .card
        display: flex
        padding: 14px
        align-items: flex-start
        flex-wrap: wrap
        .card-title
            margin: 0px
        .card-description
            margin: 0px
    .card-subtitle
        font-size: 14px
        font-weight: bold
    .card-description
        font-size: 14px
        font-weight: 400
        color: black
    .card-title
        font-size: 16px

    .left-card
        margin-left: 15px
        margin-bottom: 15px
        margin-right: 7px
        padding: 0
        flex: 67%
    .right-card
        margin-right: 15px
        margin-left: 7px
        padding: 0
        flex: 33%
        height: 33%
    .flex-between
        display: flex
        width: 100%
        justify-content: space-between
    .form-grouping
        max-width: 50rem
        border-radius: 2px
        margin: 20px 0
        padding: 18px 10px 1px 10px
        position: relative
        display: flex
        flex-direction: column
        flex: 1 1 auto
        .form-grouping__title
            background: white
            position: absolute
            left: 10px
            top: 0
            padding: 2px
            transform: translateY(-50%)

@media (max-width: 800px)
    .right-card
        flex: 100%
    .left-card
        flex: 100%


.dunning-selection-form
    .bp5-label
        font-weight: bold
    .flex-row
        display: flex
        flex-direction: row
        gap: 1em

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button
        -webkit-appearance: none
        margin: 0

    input[type=number]
        -moz-appearance: textfield

    .bp5-form-group
        width: 35%

    .bp5-popover-target
        width: 100%

    .bp5-button
        display: flex
        justify-content: space-between

.dunning-forecast
    position: sticky
    top: 0
    .dunning-forecast__display
        display: flex
        flex-direction: column
        align-items: flex-start
        gap: 15px
        padding-bottom: 1em
        min-width: 320px



        table
            width: 100%
            border-collapse: collapse
        tr:nth-child(odd)
            background-color: $color-light-gray4
        tr:last-child
            font-weight: bold
            background-color: white
        tr:nth-last-child(2)
            border-bottom: 1px solid $color-light-gray1
            font-weight: bold
        td
            height: 31px
            padding-left: 10px
        th
            padding-left: 10px
        thead
            color: $color-gray1
        tbody
            color: $color-black
    .dunning-forecast__group
        padding-left: 15px
        padding-right: 15px

    .dunning-forecast__reload
        display: flex
        .last-update
            padding: 4px
            margin-left: 6px
            color: $color-gray1


    .form-grouping
        box-shadow: 0 0 0 0px
    .dunning-forecast__spinner
        text-align: center
        flex: 1 1 100%
        width: 320px
        animation: 1s ease 0s normal forwards 1 fadein

    @keyframes fadein
        0%
            opacity: 0
        75%
            opacity: 0
        100%
            opacity: 1
