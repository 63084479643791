@import "src/variables"

.dialog
    background-color: white

.bp5-dialog-body
    overflow: auto
    padding: 20px
    margin: 0
    font-size: 14px
    font-weight: 400

.large
    width: 75em
    max-height: 90vh

.medium
    width: 55em
    max-height: 90vh

.dialog-title
    font-weight: 500
    font-size: 16px
    margin: 20px 20px 0 20px
    line-height: 19.2px

.dialog-title-2
    font-weight: 700
    font-size: 14px
    margin: 20px 0 10px 0px

.red-text
    color: red
    font-size: 12px

.required-field::after
    content: "*"
    color: red
    margin-left: 2px

.right-text
    text-align: right

.pointer
    cursor: pointer


.dunning-level-select
    .bp5-popover-target
        width: 100%
        .full-width
            resize: none
            width: 100%

            .bp5-popover-target
                width: 100%

            .bp-select-dn-level
                color: black
                border: 1px solid $color-light-gray1

            &:hover
                background-color: white

            .bp5-button-text
                width: 100%
                text-align: left
                color: black

            .bp-select-dn-level-placeholder
                white-space: nowrap
                overflow: hidden
                text-overflow: ellipsis
                width: 144.4px

                .bp5-button-text
                    color: $color-input
                    text-align: left

                &:hover
                    background-color: white
            .danger
                border-color: $color-red
                &:hover
                    border-color: $color-red

        .full-width-disabled
            resize: none
            width: 100%

            .bp5-popover-target
                width: 100%
                background: $color-light-gray-bg-disabled-input
                box-shadow: none
                color: $color-light-gray-disabled-input

            .bp5-button.bp5-disabled.bp5-fill.bp5-outlined.bp5-intent-primary.pc-button.default.bp-select-dn-level-placeholder
                border-color: $color-light-gray-bg-disabled-input
                background: $color-light-gray-bg-disabled-input

            .bp-select-dn-level-placeholder
                border-color: $color-light-gray-bg-disabled-input
                background: $color-light-gray-bg-disabled-input

            .bp-select-dn-level-disabled
                box-shadow: none
                color: $color-light-gray-disabled-input
                border-color: $color-light-gray-bg-disabled-input

            .bp5-button-text
                width: 100%
                text-align: left



.full-width
    resize: none
    width: 100%

.param-icon
    padding: 5px

.long
    width: 500px
    height: 200px

.short
    width: 200px

.bottom-line
    border-bottom: 1px solid $color-lightgrey2

.param-grid-view
    display: grid
    grid-template-columns: 1fr 2fr
    grid-column-gap: 5px
    align-items: right
    padding-top: 5px

.param-grid
    display: grid
    grid-template-columns: 1fr 2em 1fr 2em
    grid-column-gap: 5px
    align-items: center

.level-grid-view
    display: grid
    grid-template-columns: 1fr 2fr 3fr 2fr
    grid-column-gap: 5px
    align-items: right
    padding-top: 2px
    padding-bottom: 3px

.level-grid
    display: grid
    grid-template-columns: 1.5em 2em 2fr 4fr 6em 2em
    grid-column-gap: 5px
    align-items: center
    white-space: nowrap

.default-checkbox
    padding-bottom: 15px

.highlighted-text
    font-weight: 500
    font-size: 14px
    color: $color-grey1

.small-table-grid
    display: grid
    grid-template-columns: 2fr 2fr
    align-items: flex-end
    padding: 1em


.bp5-input:disabled
    cursor: text !important

.default-replacement-grid
    display: grid
    grid-template-columns: 1fr 1fr 1fr
    align-items: flex-end
    padding-right: 1em
    padding-left: 1em

.info-text
    text-align: center


.bp5-dialog-container
    transform: none !important
// this is needed to fix behaviour of drag and drop (https://github.com/vtereshyn/react-beautiful-dnd-ru/blob/master/docs/patterns/using-a-portal.md)

.dunning-file-upload
    width: 0.1px
    height: 0.1px
    opacity: 0
    overflow: hidden
    position: absolute
    z-index: -1

.subsequent-def-button
    display: grid
    grid-template-columns: 1fr auto min-content
    text-align: left
    min-width: 15em
    white-space: nowrap

.subsequent-ruleset
    display: flex

.subsequent-reset-button
    margin-left: 5px
    min-width: 2em
    text-align: center
    display: flex



