@import "src/variables"

.dunning-switch-container
    display: inline-flex
    justify-content: flex-start
    align-items: center
    gap: 10px
    margin-bottom: 10px
    cursor: pointer

.dunning-switch-container .switch
    width: 28px
    height: 16px
    position: relative
    background: $color-lightgrey2
    border-radius: 10px


.dunning-switch-container:hover .switch
    background: $color-grey4
.dunning-switch-container:hover .switch.active
    background: $color-yellow3

.dunning-switch-container .switch::before
    content: ''
    transition-property: left background
    transition-duration: 0.2s
    position: absolute
    width: 12px
    height: 12px
    border-radius: 100%
    left: 7.14%
    right: 50%
    top: 12.5%
    bottom: 12.5%
    background: white
    box-shadow: 0 0 0 1px rgb(16 22 26 / 20%), 0 1px 1px rgb(16 22 26 / 20%)

.dunning-switch-container .switch.active
    background: $color-yellow4
    border: solid 1px $color-yellow1

.dunning-switch-container .switch.active::before
    position: absolute
    left: 50%
    right: 7.14%
    top: 7.5%
    bottom: 12.5%
    background: #030307
