@import "src/variables"

.arrow-up
    position: relative
    width: 0
    height: 0
    border-left: 10px solid transparent
    border-right: 10px solid transparent
    border-bottom: 10px solid #f2f2f2
    margin: auto

.arrow-up-transparent
    position: relative
    width: 0
    height: 0
    border-left: 10px solid transparent
    border-right: 10px solid transparent
    border-bottom: 10px solid transparent
    margin: auto

.dunning-nav
    position: sticky
    z-index: 1
    top: 0
    display: flex
    white-space: nowrap
    height: 40px
    background-color: $color-navbarBlack

.dunning-nav-container
    float: left
    color: white
    padding: 10px 15px 0px 15px
    cursor: pointer

.dunning-nav-container:hover
    background-color: #39393f
    color: white
    outline: none

a.dunning-nav-container.active
    outline: none

