@import "src/variables"
.archive-wrapper
    background: $gradient-gray1
    display: flex
    flex-direction: column
    height: 100%
    padding: 4px

#dunning-container .ruleset-archive.card
    padding: 16px
    margin-top: 0
