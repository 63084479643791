@import "src/variables"
.dunning-action-page
  .flex-wrap
    flex-wrap: wrap

  .bp5-form-group
    margin: 0

  .flex-row
    display: flex
    gap: 8px

  .spacer
    flex: 1 1 auto

  .fill
    flex: 1 1 auto
    width: 100%


  .bp5-button-text
    flex: 1 1 auto

  .retry-info
    margin: 0 0 8px

  .cancellation-details
    padding: 8px 8px 16px 8px
    display: flex
    width: 50%
    > div
      flex: 1 1 auto
      display: flex
      flex-direction: column

  .execution-outcome-details
    text-align: left
    padding: 12px 12px 12px 35px
    border-left: solid 2px $color-darkgrey1
    margin: 12px 12px 12px 72px

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button
    -webkit-appearance: none
    margin: 0

  input[type=number]
    -moz-appearance: textfield
  .execution-state-filter
    .bp5-button-text
      text-overflow: ellipsis
      width: 170px
      text-wrap: nowrap
      overflow: hidden
  .execution-filter-selection
    padding: 10px
    display: flex
    flex-direction: row
    gap: 8px
    align-items: flex-end

    .type-select
      min-width: 230px

    .filter-input
      display: flex
      flex-direction: column
      align-items: flex-start

      & > span
        font-weight: bold

      &.fill
        flex: 1 1 auto


